/*
 * SonarQube
 * Copyright (C) 2009-2024 SonarSource SA
 * mailto:info AT sonarsource DOT com
 *
 * This program is free software; you can redistribute it and/or
 * modify it under the terms of the GNU Lesser General Public
 * License as published by the Free Software Foundation; either
 * version 3 of the License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU
 * Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with this program; if not, write to the Free Software Foundation,
 * Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
 */
*, ::before, ::after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position:  ;
  --tw-gradient-via-position:  ;
  --tw-gradient-to-position:  ;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
}
::-ms-backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position:  ;
  --tw-gradient-via-position:  ;
  --tw-gradient-to-position:  ;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
}
::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position:  ;
  --tw-gradient-via-position:  ;
  --tw-gradient-to-position:  ;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
}
.sw-container {
  width: 100%;
}
@media (min-width: 1280px) {
  .sw-container {
    max-width: 1280px;
  }
}
@media (min-width: 1920px) {
  .sw-container {
    max-width: 1920px;
  }
}
.sw-sr-only {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border-width: 0 !important;
}
.sw-pointer-events-none {
  pointer-events: none !important;
}
.sw-invisible {
  visibility: hidden !important;
}
.sw-fixed {
  position: fixed !important;
}
.sw-absolute {
  position: absolute !important;
}
.sw-relative {
  position: relative !important;
}
.sw-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}
.sw-inset-0 {
  inset: 0 !important;
}
.sw--bottom-3 {
  bottom: -0.75rem !important;
}
.sw-bottom-0 {
  bottom: 0 !important;
}
.sw-bottom-2 {
  bottom: 0.5rem !important;
}
.sw-bottom-4 {
  bottom: 1rem !important;
}
.sw-left-0 {
  left: 0 !important;
}
.sw-left-1\/2 {
  left: 50% !important;
}
.sw-left-12 {
  left: 3rem !important;
}
.sw-left-2 {
  left: 0.5rem !important;
}
.sw-left-3 {
  left: 0.75rem !important;
}
.sw-left-auto {
  left: auto !important;
}
.sw-right-0 {
  right: 0 !important;
}
.sw-right-10 {
  right: 2.5rem !important;
}
.sw-right-2 {
  right: 0.5rem !important;
}
.sw-right-6 {
  right: 1.5rem !important;
}
.sw-right-\[0\.375rem\] {
  right: 0.375rem !important;
}
.sw-top-0 {
  top: 0 !important;
}
.sw-top-1\/2 {
  top: 50% !important;
}
.sw-top-4 {
  top: 1rem !important;
}
.sw-top-6 {
  top: 1.5rem !important;
}
.sw-top-\[0\.375rem\] {
  top: 0.375rem !important;
}
.sw-top-\[0\.625rem\] {
  top: 0.625rem !important;
}
.sw-z-content-popup {
  z-index: 52 !important;
}
.sw-z-dropdown-menu {
  z-index: 7500 !important;
}
.sw-z-dropdown-menu-page {
  z-index: 100 !important;
}
.sw-z-filterbar {
  z-index: 50 !important;
}
.sw-z-filterbar-header {
  z-index: 55 !important;
}
.sw-z-global-navbar {
  z-index: 421 !important;
}
.sw-z-global-popup {
  z-index: 5000 !important;
}
.sw-z-modal {
  z-index: 9000 !important;
}
.sw-z-modal-overlay {
  z-index: 8500 !important;
}
.sw-z-normal {
  z-index: 2 !important;
}
.sw-z-popup {
  z-index: 420 !important;
}
.sw-z-tooltip {
  z-index: 9001 !important;
}
.sw-col-span-10 {
  grid-column: span 10 / span 10 !important;
}
.sw-col-span-12 {
  grid-column: span 12 / span 12 !important;
}
.sw-col-span-2 {
  grid-column: span 2 / span 2 !important;
}
.sw-col-span-3 {
  grid-column: span 3 / span 3 !important;
}
.sw-col-span-4 {
  grid-column: span 4 / span 4 !important;
}
.sw-col-span-8 {
  grid-column: span 8 / span 8 !important;
}
.sw-col-span-9 {
  grid-column: span 9 / span 9 !important;
}
.sw-col-span-full {
  grid-column: 1 / -1 !important;
}
.sw-col-start-2 {
  grid-column-start: 2 !important;
}
.sw-row-span-3 {
  grid-row: span 3 / span 3 !important;
}
.sw-float-right {
  float: right !important;
}
.sw-float-left {
  float: left !important;
}
.sw-clear-both {
  clear: both !important;
}
.sw-m-0 {
  margin: 0 !important;
}
.sw-m-1 {
  margin: 0.25rem !important;
}
.sw-m-1\/2 {
  margin: 0.125rem !important;
}
.sw-m-14 {
  margin: 3.75rem !important;
}
.sw-m-2 {
  margin: 0.5rem !important;
}
.sw-m-4 {
  margin: 1rem !important;
}
.-sw-mx-3 {
  margin-left: -0.75rem !important;
  margin-right: -0.75rem !important;
}
.-sw-my-2 {
  margin-top: -0.5rem !important;
  margin-bottom: -0.5rem !important;
}
.sw--mx-2 {
  margin-left: -0.5rem !important;
  margin-right: -0.5rem !important;
}
.sw--mx-6 {
  margin-left: -1.5rem !important;
  margin-right: -1.5rem !important;
}
.sw-mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.sw-mx-1 {
  margin-left: 0.25rem !important;
  margin-right: 0.25rem !important;
}
.sw-mx-2 {
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
}
.sw-mx-3 {
  margin-left: 0.75rem !important;
  margin-right: 0.75rem !important;
}
.sw-mx-4 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}
.sw-mx-5 {
  margin-left: 1.25rem !important;
  margin-right: 1.25rem !important;
}
.sw-mx-6 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}
.sw-mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}
.sw-my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.sw-my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}
.sw-my-1\/2 {
  margin-top: 0.125rem !important;
  margin-bottom: 0.125rem !important;
}
.sw-my-10 {
  margin-top: 2.5rem !important;
  margin-bottom: 2.5rem !important;
}
.sw-my-12 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}
.sw-my-14 {
  margin-top: 3.75rem !important;
  margin-bottom: 3.75rem !important;
}
.sw-my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}
.sw-my-3 {
  margin-top: 0.75rem !important;
  margin-bottom: 0.75rem !important;
}
.sw-my-4 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}
.sw-my-5 {
  margin-top: 1.25rem !important;
  margin-bottom: 1.25rem !important;
}
.sw-my-6 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}
.sw-my-8 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}
.-sw-mb-1\/2 {
  margin-bottom: -0.125rem !important;
}
.-sw-mb-4 {
  margin-bottom: -1rem !important;
}
.-sw-ml-4 {
  margin-left: -1rem !important;
}
.-sw-mr-1 {
  margin-right: -0.25rem !important;
}
.-sw-mt-3 {
  margin-top: -0.75rem !important;
}
.sw--ml-2 {
  margin-left: -0.5rem !important;
}
.sw--mr-2 {
  margin-right: -0.5rem !important;
}
.sw--mt-1 {
  margin-top: -0.25rem !important;
}
.sw--mt-2 {
  margin-top: -0.5rem !important;
}
.sw--mt-\[3px\] {
  margin-top: -3px !important;
}
.sw-mb-0 {
  margin-bottom: 0 !important;
}
.sw-mb-1 {
  margin-bottom: 0.25rem !important;
}
.sw-mb-10 {
  margin-bottom: 2.5rem !important;
}
.sw-mb-12 {
  margin-bottom: 3rem !important;
}
.sw-mb-2 {
  margin-bottom: 0.5rem !important;
}
.sw-mb-3 {
  margin-bottom: 0.75rem !important;
}
.sw-mb-4 {
  margin-bottom: 1rem !important;
}
.sw-mb-5 {
  margin-bottom: 1.25rem !important;
}
.sw-mb-6 {
  margin-bottom: 1.5rem !important;
}
.sw-mb-8 {
  margin-bottom: 2rem !important;
}
.sw-mb-9 {
  margin-bottom: 2.25rem !important;
}
.sw-mb-\[-1px\] {
  margin-bottom: -1px !important;
}
.sw-mb-page {
  margin-bottom: 1.25rem !important;
}
.sw-ml-1 {
  margin-left: 0.25rem !important;
}
.sw-ml-1\/2 {
  margin-left: 0.125rem !important;
}
.sw-ml-10 {
  margin-left: 2.5rem !important;
}
.sw-ml-12 {
  margin-left: 3rem !important;
}
.sw-ml-16 {
  margin-left: 4rem !important;
}
.sw-ml-2 {
  margin-left: 0.5rem !important;
}
.sw-ml-3 {
  margin-left: 0.75rem !important;
}
.sw-ml-4 {
  margin-left: 1rem !important;
}
.sw-ml-5 {
  margin-left: 1.25rem !important;
}
.sw-ml-6 {
  margin-left: 1.5rem !important;
}
.sw-ml-8 {
  margin-left: 2rem !important;
}
.sw-mr-0 {
  margin-right: 0 !important;
}
.sw-mr-1 {
  margin-right: 0.25rem !important;
}
.sw-mr-10 {
  margin-right: 2.5rem !important;
}
.sw-mr-12 {
  margin-right: 3rem !important;
}
.sw-mr-2 {
  margin-right: 0.5rem !important;
}
.sw-mr-3 {
  margin-right: 0.75rem !important;
}
.sw-mr-4 {
  margin-right: 1rem !important;
}
.sw-mr-5 {
  margin-right: 1.25rem !important;
}
.sw-mr-6 {
  margin-right: 1.5rem !important;
}
.sw-mr-8 {
  margin-right: 2rem !important;
}
.sw-mr-auto {
  margin-right: auto !important;
}
.sw-mt-0 {
  margin-top: 0 !important;
}
.sw-mt-1 {
  margin-top: 0.25rem !important;
}
.sw-mt-1\/2 {
  margin-top: 0.125rem !important;
}
.sw-mt-10 {
  margin-top: 2.5rem !important;
}
.sw-mt-12 {
  margin-top: 3rem !important;
}
.sw-mt-14 {
  margin-top: 3.75rem !important;
}
.sw-mt-16 {
  margin-top: 4rem !important;
}
.sw-mt-2 {
  margin-top: 0.5rem !important;
}
.sw-mt-24 {
  margin-top: 6rem !important;
}
.sw-mt-3 {
  margin-top: 0.75rem !important;
}
.sw-mt-32 {
  margin-top: 8rem !important;
}
.sw-mt-4 {
  margin-top: 1rem !important;
}
.sw-mt-5 {
  margin-top: 1.25rem !important;
}
.sw-mt-6 {
  margin-top: 1.5rem !important;
}
.sw-mt-7 {
  margin-top: 1.75rem !important;
}
.sw-mt-8 {
  margin-top: 2rem !important;
}
.sw-mt-9 {
  margin-top: 2.25rem !important;
}
.sw-mt-\[10vh\] {
  margin-top: 10vh !important;
}
.sw-mt-\[3px\] {
  margin-top: 3px !important;
}
.sw-box-border {
  box-sizing: border-box !important;
}
.sw-block {
  display: block !important;
}
.sw-inline-block {
  display: inline-block !important;
}
.sw-inline {
  display: inline !important;
}
.sw-flex {
  display: flex !important;
}
.sw-inline-flex {
  display: inline-flex !important;
}
.sw-grid {
  display: grid !important;
}
.sw-hidden {
  display: none !important;
}
.sw-h-0 {
  height: 0 !important;
}
.sw-h-4 {
  height: 1rem !important;
}
.sw-h-6 {
  height: 1.5rem !important;
}
.sw-h-7 {
  height: 1.75rem !important;
}
.sw-h-8 {
  height: 2rem !important;
}
.sw-h-9 {
  height: 2.25rem !important;
}
.sw-h-\[100vh\] {
  height: 100vh !important;
}
.sw-h-\[30px\] {
  height: 30px !important;
}
.sw-h-auto {
  height: auto !important;
}
.sw-h-control {
  height: 2.25rem !important;
}
.sw-h-fit {
  height: -moz-fit-content !important;
  height: fit-content !important;
}
.sw-h-full {
  height: 100% !important;
}
.sw-h-page {
  height: 1.25rem !important;
}
.sw-h-screen {
  height: 100vh !important;
}
.sw-max-h-\[12\.25rem\] {
  max-height: 12.25rem !important;
}
.sw-max-h-abs-200 {
  max-height: 200px !important;
}
.sw-min-h-10 {
  min-height: 2.5rem !important;
}
.sw-min-h-4 {
  min-height: 1rem !important;
}
.sw-min-h-40 {
  min-height: 10rem !important;
}
.sw-min-h-5 {
  min-height: 1.25rem !important;
}
.sw-min-h-6 {
  min-height: 1.5rem !important;
}
.sw-min-h-\[100vh\] {
  min-height: 100vh !important;
}
.sw-w-0 {
  width: 0 !important;
}
.sw-w-1 {
  width: 0.25rem !important;
}
.sw-w-1\/2 {
  width: 50% !important;
}
.sw-w-1\/3 {
  width: 33.333333% !important;
}
.sw-w-1\/4 {
  width: 25% !important;
}
.sw-w-24 {
  width: 6rem !important;
}
.sw-w-3\/4 {
  width: 75% !important;
}
.sw-w-32 {
  width: 8rem !important;
}
.sw-w-4 {
  width: 1rem !important;
}
.sw-w-6 {
  width: 1.5rem !important;
}
.sw-w-64 {
  width: 16rem !important;
}
.sw-w-9 {
  width: 2.25rem !important;
}
.sw-w-\[100px\] {
  width: 100px !important;
}
.sw-w-\[190px\] {
  width: 190px !important;
}
.sw-w-\[290px\] {
  width: 290px !important;
}
.sw-w-\[300px\] {
  width: 300px !important;
}
.sw-w-\[30px\] {
  width: 30px !important;
}
.sw-w-\[40\%\] {
  width: 40% !important;
}
.sw-w-\[400px\] {
  width: 400px !important;
}
.sw-w-\[50\%\] {
  width: 50% !important;
}
.sw-w-\[60\%\] {
  width: 60% !important;
}
.sw-w-\[650px\] {
  width: 650px !important;
}
.sw-w-\[70\%\] {
  width: 70% !important;
}
.sw-w-abs-150 {
  width: 150px !important;
}
.sw-w-abs-200 {
  width: 200px !important;
}
.sw-w-abs-300 {
  width: 300px !important;
}
.sw-w-abs-350 {
  width: 350px !important;
}
.sw-w-abs-400 {
  width: 400px !important;
}
.sw-w-abs-500 {
  width: 500px !important;
}
.sw-w-abs-600 {
  width: 600px !important;
}
.sw-w-auto {
  width: auto !important;
}
.sw-w-fit {
  width: -moz-fit-content !important;
  width: fit-content !important;
}
.sw-w-full {
  width: 100% !important;
}
.sw-w-max {
  width: -moz-max-content !important;
  width: max-content !important;
}
.sw-min-w-0 {
  min-width: 0 !important;
}
.sw-min-w-24 {
  min-width: 6rem !important;
}
.sw-min-w-4 {
  min-width: 1rem !important;
}
.sw-min-w-5 {
  min-width: 1.25rem !important;
}
.sw-min-w-\[50\%\] {
  min-width: 50% !important;
}
.sw-min-w-\[500px\] {
  min-width: 500px !important;
}
.sw-min-w-\[50px\] {
  min-width: 50px !important;
}
.sw-min-w-\[6rem\] {
  min-width: 6rem !important;
}
.sw-min-w-abs-150 {
  min-width: 150px !important;
}
.sw-min-w-abs-200 {
  min-width: 200px !important;
}
.sw-min-w-abs-250 {
  min-width: 250px !important;
}
.sw-min-w-abs-350 {
  min-width: 350px !important;
}
.sw-min-w-input-small {
  min-width: 150px !important;
}
.sw-max-w-1\/2 {
  max-width: 50% !important;
}
.sw-max-w-3\/4 {
  max-width: 75% !important;
}
.sw-max-w-32 {
  max-width: 8rem !important;
}
.sw-max-w-9\/12 {
  max-width: 75% !important;
}
.sw-max-w-\[120px\] {
  max-width: 120px !important;
}
.sw-max-w-\[22rem\] {
  max-width: 22rem !important;
}
.sw-max-w-\[250px\] {
  max-width: 250px !important;
}
.sw-max-w-\[330px\] {
  max-width: 330px !important;
}
.sw-max-w-\[400px\] {
  max-width: 400px !important;
}
.sw-max-w-\[435px\] {
  max-width: 435px !important;
}
.sw-max-w-\[50\%\] {
  max-width: 50% !important;
}
.sw-max-w-\[800px\] {
  max-width: 800px !important;
}
.sw-max-w-\[980px\] {
  max-width: 980px !important;
}
.sw-max-w-abs-150 {
  max-width: 150px !important;
}
.sw-max-w-abs-250 {
  max-width: 250px !important;
}
.sw-max-w-abs-300 {
  max-width: 300px !important;
}
.sw-max-w-abs-350 {
  max-width: 350px !important;
}
.sw-max-w-abs-400 {
  max-width: 400px !important;
}
.sw-max-w-abs-500 {
  max-width: 500px !important;
}
.sw-max-w-abs-600 {
  max-width: 600px !important;
}
.sw-max-w-abs-800 {
  max-width: 800px !important;
}
.sw-max-w-full {
  max-width: 100% !important;
}
.sw-flex-1 {
  flex: 1 1 0% !important;
}
.sw-flex-\[1_0_auto\] {
  flex: 1 0 auto !important;
}
.sw-flex-auto {
  flex: 1 1 auto !important;
}
.sw-flex-shrink {
  flex-shrink: 1 !important;
}
.sw-flex-shrink-0 {
  flex-shrink: 0 !important;
}
.sw-shrink {
  flex-shrink: 1 !important;
}
.sw-shrink-0 {
  flex-shrink: 0 !important;
}
.sw-flex-grow {
  flex-grow: 1 !important;
}
.sw-grow {
  flex-grow: 1 !important;
}
.sw-grow-0 {
  flex-grow: 0 !important;
}
.sw-cursor-default {
  cursor: default !important;
}
.sw-cursor-not-allowed {
  cursor: not-allowed !important;
}
.sw-cursor-pointer {
  cursor: pointer !important;
}
.sw-cursor-text {
  cursor: text !important;
}
.sw-select-none {
  -webkit-user-select: none !important;
     -moz-user-select: none !important;
      -ms-user-select: none !important;
          user-select: none !important;
}
.sw-select-text {
  -webkit-user-select: text !important;
     -moz-user-select: text !important;
      -ms-user-select: text !important;
          user-select: text !important;
}
.sw-resize-y {
  resize: vertical !important;
}
.sw-list-inside {
  list-style-position: inside !important;
}
.sw-list-disc {
  list-style-type: disc !important;
}
.sw-list-none {
  list-style-type: none !important;
}
.sw-appearance-none {
  -webkit-appearance: none !important;
     -moz-appearance: none !important;
          appearance: none !important;
}
.sw-auto-rows-min {
  grid-auto-rows: min-content !important;
}
.sw-grid-cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr)) !important;
}
.sw-grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
}
.sw-grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
}
.sw-grid-cols-\[1fr_3fr\] {
  grid-template-columns: 1fr 3fr !important;
}
.sw-grid-cols-\[488px_minmax\(0\,_2fr\)\] {
  grid-template-columns: 488px minmax(0, 2fr) !important;
}
.sw-flex-row {
  flex-direction: row !important;
}
.sw-flex-col {
  flex-direction: column !important;
}
.sw-flex-wrap {
  flex-wrap: wrap !important;
}
.sw-flex-nowrap {
  flex-wrap: nowrap !important;
}
.sw-content-center {
  align-content: center !important;
}
.sw-items-start {
  align-items: flex-start !important;
}
.sw-items-end {
  align-items: flex-end !important;
}
.sw-items-center {
  align-items: center !important;
}
.sw-items-baseline {
  align-items: baseline !important;
}
.sw-items-stretch {
  align-items: stretch !important;
}
.sw-justify-start {
  justify-content: flex-start !important;
}
.sw-justify-end {
  justify-content: flex-end !important;
}
.sw-justify-center {
  justify-content: center !important;
}
.sw-justify-between {
  justify-content: space-between !important;
}
.sw-justify-around {
  justify-content: space-around !important;
}
.sw-justify-stretch {
  justify-content: stretch !important;
}
.sw-gap-0 {
  gap: 0 !important;
}
.sw-gap-1 {
  gap: 0.25rem !important;
}
.sw-gap-1\/2 {
  gap: 0.125rem !important;
}
.sw-gap-12 {
  gap: 3rem !important;
}
.sw-gap-2 {
  gap: 0.5rem !important;
}
.sw-gap-3 {
  gap: 0.75rem !important;
}
.sw-gap-4 {
  gap: 1rem !important;
}
.sw-gap-6 {
  gap: 1.5rem !important;
}
.sw-gap-8 {
  gap: 2rem !important;
}
.sw-gap-x-12 {
  column-gap: 3rem !important;
}
.sw-gap-y-3 {
  row-gap: 0.75rem !important;
}
.sw-gap-y-6 {
  row-gap: 1.5rem !important;
}
.sw-space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(1rem*var(--tw-space-x-reverse)) !important;
  margin-left: calc(1rem*(1 - var(--tw-space-x-reverse))) !important;
}
.sw-space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(0.25rem*(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(0.25rem*var(--tw-space-y-reverse)) !important;
}
.sw-self-start {
  align-self: flex-start !important;
}
.sw-self-end {
  align-self: flex-end !important;
}
.sw-self-center {
  align-self: center !important;
}
.sw-overflow-auto {
  overflow: auto !important;
}
.sw-overflow-hidden {
  overflow: hidden !important;
}
.sw-overflow-visible {
  overflow: visible !important;
}
.sw-overflow-x-auto {
  overflow-x: auto !important;
}
.sw-overflow-y-auto {
  overflow-y: auto !important;
}
.sw-overflow-x-hidden {
  overflow-x: hidden !important;
}
.sw-overflow-y-scroll {
  overflow-y: scroll !important;
}
.sw-truncate {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}
.sw-text-ellipsis {
  text-overflow: ellipsis !important;
}
.sw-whitespace-normal {
  white-space: normal !important;
}
.sw-whitespace-nowrap {
  white-space: nowrap !important;
}
.sw-whitespace-pre {
  white-space: pre !important;
}
.sw-whitespace-pre-wrap {
  white-space: pre-wrap !important;
}
.sw-break-words {
  overflow-wrap: break-word !important;
}
.sw-break-all {
  word-break: break-all !important;
}
.sw-rounded-0 {
  border-radius: 0 !important;
}
.sw-rounded-1 {
  border-radius: 0.25rem !important;
}
.sw-rounded-1\/2 {
  border-radius: 0.125rem !important;
}
.sw-rounded-2 {
  border-radius: 0.5rem !important;
}
.sw-rounded-pill {
  border-radius: 625rem !important;
}
.sw-rounded-b-1 {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}
.sw-rounded-b-2 {
  border-bottom-right-radius: 0.5rem !important;
  border-bottom-left-radius: 0.5rem !important;
}
.sw-rounded-l-1 {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}
.sw-rounded-l-2 {
  border-top-left-radius: 0.5rem !important;
  border-bottom-left-radius: 0.5rem !important;
}
.sw-rounded-r-2 {
  border-top-right-radius: 0.5rem !important;
  border-bottom-right-radius: 0.5rem !important;
}
.sw-rounded-t-1 {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}
.sw-rounded-tl-1 {
  border-top-left-radius: 0.25rem !important;
}
.sw-rounded-tr-1 {
  border-top-right-radius: 0.25rem !important;
}
.sw-border {
  border-width: 1px !important;
}
.sw-border-0 {
  border-width: 0px !important;
}
.sw-border-y-0 {
  border-top-width: 0px !important;
  border-bottom-width: 0px !important;
}
.sw-border-t-0 {
  border-top-width: 0px !important;
}
.sw-border-solid {
  border-style: solid !important;
}
.sw-border-none {
  border-style: none !important;
}
.sw-border-transparent {
  border-color: transparent !important;
}
.sw-bg-blue-200 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(191 219 254 / var(--tw-bg-opacity)) !important;
}
.sw-bg-gray-200 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity)) !important;
}
.sw-bg-green-200 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(187 247 208 / var(--tw-bg-opacity)) !important;
}
.sw-bg-inherit {
  background-color: inherit !important;
}
.sw-bg-purple-200 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(233 213 255 / var(--tw-bg-opacity)) !important;
}
.sw-bg-red-200 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(254 202 202 / var(--tw-bg-opacity)) !important;
}
.sw-bg-white {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity)) !important;
}
.sw-bg-yellow-200 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(254 240 138 / var(--tw-bg-opacity)) !important;
}
.sw-object-contain {
  object-fit: contain !important;
}
.sw-p-0 {
  padding: 0 !important;
}
.sw-p-1 {
  padding: 0.25rem !important;
}
.sw-p-1\/2 {
  padding: 0.125rem !important;
}
.sw-p-10 {
  padding: 2.5rem !important;
}
.sw-p-2 {
  padding: 0.5rem !important;
}
.sw-p-3 {
  padding: 0.75rem !important;
}
.sw-p-4 {
  padding: 1rem !important;
}
.sw-p-5 {
  padding: 1.25rem !important;
}
.sw-p-6 {
  padding: 1.5rem !important;
}
.sw-p-8 {
  padding: 2rem !important;
}
.sw-p-9 {
  padding: 2.25rem !important;
}
.sw-px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.sw-px-1 {
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
}
.sw-px-1\/2 {
  padding-left: 0.125rem !important;
  padding-right: 0.125rem !important;
}
.sw-px-14 {
  padding-left: 3.75rem !important;
  padding-right: 3.75rem !important;
}
.sw-px-2 {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}
.sw-px-3 {
  padding-left: 0.75rem !important;
  padding-right: 0.75rem !important;
}
.sw-px-4 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}
.sw-px-5 {
  padding-left: 1.25rem !important;
  padding-right: 1.25rem !important;
}
.sw-px-6 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}
.sw-px-8 {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}
.sw-px-\[0\.125rem\] {
  padding-left: 0.125rem !important;
  padding-right: 0.125rem !important;
}
.sw-px-\[0\.625rem\] {
  padding-left: 0.625rem !important;
  padding-right: 0.625rem !important;
}
.sw-px-\[5px\] {
  padding-left: 5px !important;
  padding-right: 5px !important;
}
.sw-px-\[8px\] {
  padding-left: 8px !important;
  padding-right: 8px !important;
}
.sw-px-page {
  padding-left: 1.25rem !important;
  padding-right: 1.25rem !important;
}
.sw-py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.sw-py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}
.sw-py-1\/2 {
  padding-top: 0.125rem !important;
  padding-bottom: 0.125rem !important;
}
.sw-py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}
.sw-py-3 {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}
.sw-py-4 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}
.sw-py-6 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}
.sw-py-8 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}
.sw-py-\[0\.03125rem\] {
  padding-top: 0.03125rem !important;
  padding-bottom: 0.03125rem !important;
}
.sw-py-\[1px\] {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
}
.sw-py-\[2px\] {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}
.sw-pb-0 {
  padding-bottom: 0 !important;
}
.sw-pb-1 {
  padding-bottom: 0.25rem !important;
}
.sw-pb-10 {
  padding-bottom: 2.5rem !important;
}
.sw-pb-2 {
  padding-bottom: 0.5rem !important;
}
.sw-pb-24 {
  padding-bottom: 6rem !important;
}
.sw-pb-3 {
  padding-bottom: 0.75rem !important;
}
.sw-pb-4 {
  padding-bottom: 1rem !important;
}
.sw-pb-6 {
  padding-bottom: 1.5rem !important;
}
.sw-pb-8 {
  padding-bottom: 2rem !important;
}
.sw-pl-0 {
  padding-left: 0 !important;
}
.sw-pl-1 {
  padding-left: 0.25rem !important;
}
.sw-pl-10 {
  padding-left: 2.5rem !important;
}
.sw-pl-12 {
  padding-left: 3rem !important;
}
.sw-pl-2 {
  padding-left: 0.5rem !important;
}
.sw-pl-3 {
  padding-left: 0.75rem !important;
}
.sw-pl-4 {
  padding-left: 1rem !important;
}
.sw-pl-5 {
  padding-left: 1.25rem !important;
}
.sw-pl-6 {
  padding-left: 1.5rem !important;
}
.sw-pl-8 {
  padding-left: 2rem !important;
}
.sw-pr-0 {
  padding-right: 0 !important;
}
.sw-pr-1 {
  padding-right: 0.25rem !important;
}
.sw-pr-10 {
  padding-right: 2.5rem !important;
}
.sw-pr-2 {
  padding-right: 0.5rem !important;
}
.sw-pr-24 {
  padding-right: 6rem !important;
}
.sw-pr-3 {
  padding-right: 0.75rem !important;
}
.sw-pr-4 {
  padding-right: 1rem !important;
}
.sw-pr-8 {
  padding-right: 2rem !important;
}
.sw-pt-0 {
  padding-top: 0 !important;
}
.sw-pt-1 {
  padding-top: 0.25rem !important;
}
.sw-pt-1\/2 {
  padding-top: 0.125rem !important;
}
.sw-pt-10 {
  padding-top: 2.5rem !important;
}
.sw-pt-12 {
  padding-top: 3rem !important;
}
.sw-pt-16 {
  padding-top: 4rem !important;
}
.sw-pt-2 {
  padding-top: 0.5rem !important;
}
.sw-pt-3 {
  padding-top: 0.75rem !important;
}
.sw-pt-4 {
  padding-top: 1rem !important;
}
.sw-pt-5 {
  padding-top: 1.25rem !important;
}
.sw-pt-6 {
  padding-top: 1.5rem !important;
}
.sw-pt-8 {
  padding-top: 2rem !important;
}
.sw-pt-\[10vh\] {
  padding-top: 10vh !important;
}
.sw-text-left {
  text-align: left !important;
}
.sw-text-center {
  text-align: center !important;
}
.sw-text-right {
  text-align: right !important;
}
.sw-text-start {
  text-align: start !important;
}
.sw-align-baseline {
  vertical-align: baseline !important;
}
.sw-align-top {
  vertical-align: top !important;
}
.sw-align-middle {
  vertical-align: middle !important;
}
.sw-align-bottom {
  vertical-align: bottom !important;
}
.sw-align-text-top {
  vertical-align: text-top !important;
}
.sw-align-text-bottom {
  vertical-align: text-bottom !important;
}
.sw-align-sub {
  vertical-align: sub !important;
}
.sw-font-sans {
  font-family: Inter, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
}
.sw-text-\[0\.75rem\] {
  font-size: 0.75rem !important;
}
.sw-text-base {
  font-size: 1rem !important;
  line-height: 1.5rem !important;
}
.sw-text-lg {
  font-size: 1.5rem !important;
  line-height: 1.75rem !important;
}
.sw-text-sm {
  font-size: 0.875rem !important;
  line-height: 1.25rem !important;
}
.sw-text-xs {
  font-size: 0.75rem !important;
  line-height: 1rem !important;
}
.sw-font-bold {
  font-weight: 700 !important;
}
.sw-font-regular {
  font-weight: 400 !important;
}
.sw-font-semibold {
  font-weight: 600 !important;
}
.sw-uppercase {
  text-transform: uppercase !important;
}
.sw-capitalize {
  text-transform: capitalize !important;
}
.sw-not-italic {
  font-style: normal !important;
}
.sw-leading-4 {
  line-height: 1rem !important;
}
.sw-leading-6 {
  line-height: 1.5rem !important;
}
.sw-leading-\[0\.938rem\] {
  line-height: 0.938rem !important;
}
.sw-leading-\[1\.125rem\] {
  line-height: 1.125rem !important;
}
.sw-tracking-tight {
  letter-spacing: -0.025em !important;
}
.sw-text-gray-400 {
  --tw-text-opacity: 1 !important;
  color: rgb(156 163 175 / var(--tw-text-opacity)) !important;
}
.sw-underline {
  -webkit-text-decoration-line: underline !important;
          text-decoration-line: underline !important;
}
.sw-line-through {
  -webkit-text-decoration-line: line-through !important;
          text-decoration-line: line-through !important;
}
.sw-no-underline {
  -webkit-text-decoration-line: none !important;
          text-decoration-line: none !important;
}
.sw-opacity-50 {
  opacity: 0.5 !important;
}
.sw-opacity-60 {
  opacity: 0.6 !important;
}
.sw-shadow-none {
  --tw-shadow: 0 0 #0000 !important;
  --tw-shadow-colored: 0 0 #0000 !important;
  box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow) !important;
}
.sw-outline-none {
  outline: 2px solid transparent !important;
  outline-offset: 2px !important;
}
.sw-transition {
  transition-property: color, background-color, border-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-text-decoration-color, -webkit-backdrop-filter !important;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter !important;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-text-decoration-color, -webkit-backdrop-filter !important;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  transition-duration: 150ms !important;
}
.sw-transition-none {
  transition-property: none !important;
}
.sw-duration-500 {
  transition-duration: 500ms !important;
}
.sw-duration-75 {
  transition-duration: 75ms !important;
}
.sw-ease-in {
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1) !important;
}
.sw-ease-in-out {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
}
.sw-heading-xl {
  font-family: Inter, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  font-size: 2.25rem !important;
  line-height: 3rem !important;
  font-weight: 600 !important;
}
.sw-heading-lg {
  font-family: Inter, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  font-size: 1.5rem !important;
  line-height: 1.75rem !important;
  font-weight: 600 !important;
}
.sw-heading-md {
  font-family: Inter, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  font-size: 1.313rem !important;
  line-height: 1.75rem !important;
  font-weight: 600 !important;
}
.sw-body-md {
  font-family: Inter, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  font-size: 1rem !important;
  line-height: 1.5rem !important;
  font-weight: 400 !important;
}
.sw-body-md-highlight {
  font-family: Inter, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  font-size: 1rem !important;
  line-height: 1.5rem !important;
  font-weight: 600 !important;
}
.sw-body-sm {
  font-family: Inter, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  font-size: 0.875rem !important;
  line-height: 1.25rem !important;
  font-weight: 400 !important;
}
.sw-body-xs {
  font-family: Inter, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  font-size: 0.75rem !important;
  line-height: 1rem !important;
  font-weight: 400 !important;
}
.sw-body-sm-highlight {
  font-family: Inter, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  font-size: 0.875rem !important;
  line-height: 1.25rem !important;
  font-weight: 600 !important;
}
.sw-code {
  font-family: Ubuntu Mono, ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
  font-size: 0.875rem !important;
  line-height: 1.125rem !important;
  font-weight: 400 !important;
}
.sw-code-highlight {
  font-family: Ubuntu Mono, ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
  font-size: 0.875rem !important;
  line-height: 1.125rem !important;
  font-weight: 700 !important;
}
.sw-code-comment {
  font-family: Ubuntu Mono, ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
  font-size: 0.875rem !important;
  line-height: 1.125rem !important;
  font-style: italic !important;
}

*,
*:before,
*:after {
  margin: 0;
  padding: 0;
}

*:focus-visible {
  outline: 2px dotted #297bae;
}

html,
body {
  background-color: white;
}

body {
  overflow-y: scroll;
}

fieldset,
img {
  border: 0;
}

address,
caption,
cite,
code,
dfn,
th,
var {
  font-style: normal;
  font-weight: normal;
}

caption,
th {
  text-align: left;
}

q:before,
q:after {
  content: '';
}

abbr,
acronym {
  border: 0;
  font-variant: normal;
}

input,
textarea,
select {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
}

select,
input,
button,
textarea {
  font-size: inherit;
  font-family: inherit;
}

textarea {
  min-height: 40px;
}

/*Remove button padding in FF*/
select::-moz-focus-inner,
input::-moz-focus-inner,
button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

pre,
code,
kbd,
samp,
tt {
  font-family: Consolas, 'Ubuntu Mono', 'Liberation Mono', Menlo, Courier, monospace;
  font-size: 12px;
  line-height: 18px;
}

svg,
img {
  vertical-align: top;
  overflow: visible;
}

hr {
  margin: 4px 0;
  padding: 0;
  height: 1px;
  border: none;
  background-color: #e6e6e6;
}

@media (min-width: 1920px) {
  .lg\:sw-w-\[390px\] {
    width: 390px !important;
  }
}
